<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[id]"
      @hidden="hidden">

      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>

      <ListFilter @applyFilter="applyFilter"/>
      
      <div class="grid-toolbar border" v-if="allowManage">
        <template v-if="canAdd()">
          <span :id="`BTN_ADD_${id}`">
            <b-btn @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top">
            {{ $t('button.add') }}
          </b-popover>
        </template>
        <template v-if="canView()">
          <span :id="`BTN_EDIT_${id}`">
            <b-btn :disabled="disableEdit" @click="modalOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top">
            {{ $t('button.edit') }}
          </b-popover>
        </template>
        <template v-if="canAdd()">
          <span :id="`BTN_DUPLICATE_${id}`">
            <b-btn :disabled="disableDuplicate" @click="showDuplicateDialog"><font-awesome-icon :icon="['far','clone']"/></b-btn>  
          </span>
          <b-popover :target="`BTN_DUPLICATE_${id}`" triggers="hover" placement="top">
            {{ $t('button.duplicate') }}
          </b-popover>
        </template>
        <template v-if="canDelete()">
          <span :id="`BTN_DELETE_${id}`">
            <b-btn :disabled="disableDelete" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          </span>
          <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top">
            {{ $t('button.delete') }}
          </b-popover>
        </template>
        <span v-if="canAdd()" :id="`BTN_IMPORT_DOCUMENT_${id}`">
          <b-btn @click="fileImport"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
        </span>
        <b-popover :target="`BTN_IMPORT_DOCUMENT_${id}`" triggers="hover" placement="top">
          {{ $t('customer.button.import_document') }}
        </b-popover>
        <span :id="`BTN_EXPORT_DOCUMENT_${id}`">
          <b-btn @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
        </span>
        <b-popover :target="`BTN_EXPORT_DOCUMENT_${id}`" triggers="hover" placement="top">
          {{ $t('customer.button.export_document') }}
        </b-popover>
        <span @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
          <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'palette']"/>
            </template>
            <b-dropdown-group :header="$t('colorby')">
              <b-dropdown-item @click="onColorChange('none')" href="#">
                <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('customer')" href="#">
                <span class="action-item-label">{{ $t('customer.coloring.customer') }}</span><font-awesome-icon class="active-check" v-if="coloring.customer" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('location')" href="#">
                <span class="action-item-label">{{ $t('customer.coloring.location') }}</span><font-awesome-icon class="active-check" v-if="coloring.location" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </span>
      </div>

      <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height" id="customer-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.uuId"
            noRowsOverlayComponent="noRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            pagination
            :paginationPageSize="1000"
            :cacheBlockSize="10000"
            :rowData="rowData"
            rowModelType="serverSide"
            :rowSelection="multiple? 'multiple':'single'"
            rowMultiSelectWithClick
            :serverSideInfiniteScroll="true"
            :sideBar="false"
            suppressDragLeaveHidesColumns
            suppressCellFocus
            suppressContextMenu
            suppressMultiSort
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ cancel }">
        <template v-if="allowSelect">
          <b-button :disabled="disableOk" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <CustomerModal v-if="customerShow" :id="customerId" :show.sync="customerShow" @success="customerSuccess" :title="customerTitle"/>

    <b-modal :title="duplicateTitle"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-group :label="$t('customer.field.name')" label-for="name">
        <b-input-group>
          <b-form-input id="name" type="text"
            :data-vv-as="$t('customer.field.name')"
            data-vv-name="duplicate.name"
            data-vv-delay="500"
            trim
            v-model="duplicateName"/>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicate.name') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('customer.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(selected.length > 1? 'customer.confirmation.delete_plural':'customer.confirmation.delete') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <!--Gantt Import Dialog -->
    <GanttImportDialog :properties="[{ value: 'color', text: $t('field.color') }, { value: 'contact', text: $t('customer.field.contacts') }, { value: 'desc', text: $t('customer.field.description') }, { value: 'identifier', text: $t('field.identifier') }, { value: 'location', text: $t('customer.field.location') }, { value: 'name', text: $t('customer.field.name') }, { value: 'nickname', text: $t('customer.field.nickName') }, { value: 'socials', text: $t('customer.field.socials') }, { value: 'tag', text: $t('field.tag') }, { value: 'websites', text: $t('customer.field.websites') }]" :mode="'CUSTOMER'" :show="docImportShow"
      :title="$t('customer.button.import_document')"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import { filterOutViewDenyProperties, columnDefSortFunc } from '@/views/management/script/common';
import { strRandom, addTags, invertColor, objectClone } from '@/helpers';
import { customerService, viewProfileService, customerLinkLocationService, contactService,
         customerLinkTagService } from '@/services';
import { fieldValidateUtil } from '@/script/helper-field-validate';

import ListFilter from '@/components/ListFilter/ListFilter';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import ArrayCellRenderer from '@/components/Aggrid/CellRenderer/Array';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      customerService.list(self.buildParams(params)).then((response) => {
        self.totalRecords = response.arg_total;
        params.successCallback(response.data, response.arg_total);
        if(self.gridApi && self.modalShow) {
          self.gridApi.deselectAll();
          if(self.selected && self.selected.length > 0) {
            const selected = cloneDeep(self.selected);
            self.gridApi.forEachNode(function(node) {
              if (selected.includes(node.data.uuId)) {
                node.setSelected(true);
                selected.splice(selected.indexOf(node.data.uuId), 1);
              }
            });
          }
        }
        self.gridApi.hideOverlay();
        if (response.arg_total === 0) {
          self.showNoRowLabel(null)
        }
      })
      .catch(e => {
        params.successCallback([], 0);
        if (e != null && e.response != null && e.response.status == 403) {
          self.showNoRowLabel(self.$t('entity_selector.error.insufficient_permission_to_show_data'))
        }
      });
    }
  }
}

export default {
  name: 'CustomerSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    CustomerModal: () => import('@/components/modal/CustomerModal'),
    GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog'),
    ListFilter,
    AlertFeedback: () => import('@/components/AlertFeedback'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),

    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    'detailLinkCellRenderer': DetailLinkCellRenderer,
    'arrayCellRenderer': ArrayCellRenderer,
    'colorCellRenderer': ColorCellRenderer
    //Overlay
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      id: `CUSTOMER_LIST_${strRandom(5)}`,
      permissionName: "CUSTOMER",
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
  
      modalShow: false,
      disableEdit: true,
      disableDelete: true,
      disableDuplicate: true,
      disableOk: true,
      selected: [],

      customerId: null,
      customerShow: false,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,

      confirmDeleteShow: false,
      totalRecords: 0,
      
      searchFilter: '',

      duplicateShow: false,
      duplicateName: null,
      duplicateInProgress: false,
      
      docImportShow: false,
      
      coloring: {
        none: true,
        customer: false,
        location: false
      },
      lastOpenColumnMenuParams: null,
      noRowsMessage: null,
      noRowsOverlayComponentParams: null
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    const profileKey = 'customer_selector_list';
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    const self = this;
    this.gridOptions = {
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.disableDuplicate = self.selected.length != 1;
        self.disableDelete = self.selected.length < 1;
        self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.columnApi
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
        }
        const cols = params.columnApi.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.columnApi.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.columnApi.moveColumn(c.colId, index);
        }

        const columns = params.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onDragStopped: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null;
          self.gridApi.sizeColumnsToFit();
          self.$nextTick(() => {
            const columns = event.columnApi.getAllDisplayedColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          })
        }
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('customer.field.name'),
        field: 'uuId',
        cellRenderer: 'detailLinkCellRenderer',
        checkboxSelection: true,
        lockVisible: true,
        pinned: 'left',
        minWidth: 200,
        hide: false,
        sort: 'asc',
        getRowStyle: params => {
          if (params.data &&
              params.data.color &&
              self.coloring.customer) {
              return { background: params.node.data.color, color: invertColor(params.node.data.color, true) };
          }
          else if (params.data &&
              Array.isArray(params.data.locationColor) &&
              params.data.locationColor.length > 0 &&
              params.data.locationColor[0] !== '' &&
              self.coloring.location) {
              return { background: params.node.data.locationColor[0], color: invertColor(params.node.data.locationColor[0], true) };
          }
        }
      },
      {
        headerName: this.$t('customer.field.country'),
        field: 'locationCountry',
        hide: false
      },
      {
        headerName: this.$t('customer.field.location'),
        field: 'locationName',
        hide: true
      },
      {
        headerName: this.$t('customer.field.projects'),
        field: 'projectNames',
        hide: false
      },
      {
        headerName: this.$t('customer.field.nickName'),
        field: 'nickName'
      },
      {
        headerName: this.$t('customer.field.description'),
        field: 'description'
      },
      {
        headerName: self.$t('customer.field.socials'),
        field: 'socials',
        minWidth: 100,
        hide: true,
        cellRenderer: 'arrayCellRenderer',
        cellRendererParams: { labelField: 'data' }
      },
      {
        headerName: self.$t('customer.field.websites'),
        field: 'websites',
        minWidth: 100,
        hide: true
      },
      {
        headerName: self.$t('customer.field.contacts'),
        field: 'contacts',
        minWidth: 100,
        hide: true,
        cellRenderer: 'arrayCellRenderer',
        cellRendererParams: { labelField: 'firstName' }
      },
      {
        headerName: self.$t('customer.field.tag'),
        field: 'tag',
        minWidth: 100,
        hide: true
      },
      {
        headerName: self.$t('field.color'),
        field: 'color',
        cellRenderer: 'colorCellRenderer',
        minWidth: 100,
        hide: true
      },
      {
        headerName: this.$t('field.identifier_full'),
        field: 'identifier'
      }
    ];
    const linkedEntities = [
      { selector: 'CUSTOMER.TAG', field: 'tag', properties: ['name'] }
      , { selector: 'CUSTOMER.LOCATION', field: 'locationName', properties: ['name'] }
      , { selector: 'CUSTOMER.LOCATION', field: 'locationCountry', properties: ['countryCode'] }
      , { selector: 'CUSTOMER.CONTACT', field: 'contacts', properties: ['firstName','lastName'] }
      , { selector: 'CUSTOMER.PROJECT', field: 'projectNames', properties: ['CONTACT'] }
    ]
    filterOutViewDenyProperties(this.columnDefs, 'CUSTOMER', linkedEntities);
    this.columnDefs.sort(columnDefSortFunc);

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      hide: true,
      lockPinned: true,
      menuTabs: ['columnsMenuTab']
    };

    this.context = {
      componentParent: self
    };
  },
  mounted() {
    this.loadViewProfile();
  },
  created() {
    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
    this.modifiedList = [];
    this.updateModalShow(this.show);
  },
  beforeDestroy() {
    this.userId = null;
    this.newToProfile = null;
    this.modifiedList = null;
  },
  watch: {
    async show(newValue) {
      
      if(newValue) {
        this.resetAlert();
        this.searchFilter = "";
        await this.loadViewProfile();
      } else {
        this.modifiedList.splice(0, this.modifiedList.length);
        //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
        this.gridApi = null;
        this.gridColumnApi = null;
      }
      this.updateModalShow(newValue);
    }
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    customerTitle() {
      return this.customerId && this.customerId.indexOf('CUSTOMER_NEW') == -1? this.$t('customer.title_detail'): this.$t('customer.title_new');
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    labelTitle() {
      return this.title? this.title : this.$t('customer.title_selector');
    },
    showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name');
    },
    duplicateTitle() {
      return this.$t('customer.title_duplicate');
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      
      const self = this;
      const updateData = () => {
        params.api.setServerSideDatasource(new ServerSideDatasource(self));
      };

      updateData();
      
    },
    buildParams({ request: {sortModel, endRow, startRow} }) {
    
      const params = {
        start: !self.exportData ? startRow : 0,
        limit: !self.exportData ? endRow - startRow + 1 : -1,
      };
      params.ksort = []
      params.order = []
      for(let i = 0, len = sortModel.length; i < len; i++) {
        params.ksort.push(sortModel[i].colId);
        params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
      }
      params.filter = this.searchFilter;
      return params;
    },
    modalOpen(isNew) {
      if(isNew) {
        this.customerId = `CUSTOMER_NEW_${strRandom(5)}`;
      } else {
        this.customerId = this.selected[0];
      }
      this.customerShow = true;
      this.resetAlert();
    },
    customerSuccess(payload) {
      this.modifiedList.push({ action: 'UPDATE', value: payload.value })//Expect payload.value object contains uuId, name and color properties
      this.gridApi.refreshServerSide({ purge: true });
      this.resetAlert({ msg: payload.msg });
      this.scrollToTop();
    },
    rowDelete() {
      this.confirmDeleteShow = true;
    },
    async confirmDeleteOk(){ 
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('customer.progress.deleting');
      const selectedNodes = this.gridOptions.api.getSelectedNodes();
      const toDeleteIdNames = selectedNodes.map(node => { return { uuId: node.data.uuId, name: node.data.name != null? node.data.name : node.data.label } });
      const toDeleteIds = this.selected.map(i => { return { uuId: i } });

      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`customer.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];

      await customerService.remove(toDeleteIds)
      .then(response => {
        if (response.status == 207) {
          alertState = alertStateEnum.WARNING;
          alertMsg = this.$t('customer.delete_partial');
          alertMsgDetailTitle = this.$t(`customer.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = response.data[response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            if (feedback.clue == 'OK') {
              continue;
            }
            const targetId = toDeleteIds[i].uuId;
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
      })
      .catch(e => {
        alertState = alertStateEnum.ERROR;
        alertMsg = this.$t(`customer.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        if (e.response && e.response.status == 422) {
          alertMsgDetailTitle = this.$t(`customer.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = e.response.data[e.response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            if (feedback.clue == 'OK') {
              continue;
            }
            const targetId = toDeleteIds[i].uuId;
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
      });

      if (alertState !== alertStateEnum.ERROR) {
        this.modifiedList.push(selectedNodes.map(i => { return { action: 'DELETE', value: { uuId: i.uuId, name: i.name } } }));
        this.gridApi.refreshServerSide({ purge: true });
      }

      const alertPayload = {
        msg: alertMsg,
        alertState: alertState
      }
      if (alertMsgDetailList.length > 0) {
        alertPayload.details = alertMsgDetailList;
        alertPayload.detailTitle = alertMsgDetailTitle;
      }
      this.inProgressShow = false;
      this.resetAlert(alertPayload);
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.id}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    },
    updateSelected(newValue) {
      this.selected.splice(0, this.selected.length, ...newValue);
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    ok() {
      const details = this.gridApi.getSelectedNodes().map(i => { return {uuId: i.data.uuId, name: i.data.name, color: i.data.color} });
      this.$emit('ok', { ids: [...this.selected], details: details, modifiedList: objectClone(this.modifiedList) });
      this.$emit('input', [...this.selected]);
      this.$emit('update:show', false);
    },
    hidden() {
      this.$emit('cancel', { modifiedList: objectClone(this.modifiedList) });
      this.selected.splice(0, this.selected.length);
      this.$emit('update:show', false);
      
    },
    openDetail(id){
      this.customerId = id;
      this.customerShow = true;
      this.resetAlert();
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    applyFilter(pattern) {
      this.searchFilter = pattern;
      this.gridApi.refreshServerSide({ purge: true });
    },
    showDuplicateDialog() {
      this.resetAlert();
      const origName = this.gridApi.getRowNode(this.selected[0]).data.name;
      this.duplicateName = `${origName} (Copy)`;
      this.duplicateShow = true;
    },
    duplicateOk() {
      this.duplicateEntity();
    },
    duplicateEntity: debounce(function() {
      this.duplicateInProgress = true;
      if(!this.duplicateName || this.duplicateName.trim().length < 1) {
        this.errors.add({
          field: `duplicate.name`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('customer.field.name')])
        });
      }
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          customerService.clone(this.selected[0], { name: this.duplicateName })
          .then(() => {
            this.resetAlert({ msg: this.$t('customer.duplicate') });
            this.gridApi.refreshServerSide({ purge: true });
          })
          .catch(e => {
            let  alertMsg = this.$t('error.clone.failure', [this.$t('entityType.CUSTOMER')]);
            if(e.response && e.response.status == 404 && e.response.data && e.response.data.jobClue != null) {
              const clue = e.response.data.jobClue.clue;
              if ('Unknown_holder' === clue) {
                alertMsg = this.$t('customer.error.duplicate_not_found');
              }
              this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
              this.scrollToTop();
            } else {
              this.httpAjaxError(e);
            }
          })
          .finally(() => {
            this.duplicateShow = false;
            this.errors.clear();
            //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
            this.$nextTick(() => {
              this.duplicateInProgress = false;
            });
          });
        } else {
          this.duplicateInProgress = false;
        }
      });
    }, 100),
    duplicateCancel() {
      this.duplicateShow = false;
      this.errors.clear();
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadViewProfile() {
      const self = this;
      await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
         
          if (typeof self.settings.customer_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.customer_selector_list);
            self.coloring.none = self.settings.customer_selector_coloring ? self.settings.customer_selector_coloring.none : true;
            self.coloring.company = self.settings.customer_selector_coloring ? self.settings.customer_selector_coloring.company : false;
            self.coloring.location = self.settings.customer_selector_coloring ? self.settings.customer_selector_coloring.location : false;
          } else {
            this.newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of data.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      
      if (data != null && data.gridOptions != null && data.gridOptions.api != null) {
        data.gridOptions.api.setColumnDefs([]);
        data.gridOptions.api.setColumnDefs(data.columnDefs);
      }
      return false;
    },
    fileImport() {
      this.docImportShow = true;
    },
    parsePot(data) {
      if (typeof data === 'undefined') {
        return '';
      }
      
      if (typeof data === 'string' &&
          data.length !== 0) {
        data = JSON.parse(data);
      }
      
      const ret = [];
      for (const d of data) {
        ret.push(`${d.kind} ${d.data}`);
      }
      return ret.join('; ');
    },
    parseLot(data) {
      if (typeof data === 'undefined') {
        return '';
      }
      
      if (typeof data === 'string' &&
          data.length !== 0) {
        return JSON.parse(data);
      }
      
      return '';
    },
    processCellCallback(self) {
      return function(params) {
        if (params.column.colId.indexOf('uuId') !== -1) {
          return params.node.data.name;
        }
        else if (params.value &&
                 (params.column.colId === 'socials' ||
                 params.column.colId === 'phone')) {
          return params.value.map(i => {
            return `${i.kind}: ${i.data}`;
            }).join(', ');        
        }
        else if (params.value &&
                 params.column.colId === 'contacts') {
          const contacts = [];
          for (const contact of params.value) {
            contacts.push(`${self.$t('contact.field.firstName')}: ${contact.firstName}, ${self.$t('contact.field.lastName')}: ${contact.lastName ? contact.lastName : ''}, ${self.$t('contact.field.position')}: ${contact.position}, ${self.$t('contact.field.company')}: ${contact.company ? contact.company : ''}, ${self.$t('contact.field.phones')}: ${self.parsePot(contact.phone)}, ${self.$t('contact.field.socials')}: ${self.parsePot(contact.socials)}, ${self.$t('contact.field.emails')}: ${self.parseLot(contact.emails)}, ${self.$t('contact.field.websites')}: ${self.parseLot(contact.websites)}`);        
          }
          return contacts.join('|');
        }
        return params.value;
      }
    },
    fileExport() {
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('dataview.exporting');
      this.exportData = true;
      
      let listener = () =>{
        
        const keys = this.gridColumnApi
          .getAllColumns()
          .map(column => column.getColId())
      
        const self = this;
        this.gridApi.exportDataAsExcel({ 
          fileName: 'Customers'
          , sheetName: 'Customers'
          , columnKeys: keys
          , rowHeight: 20
          , processCellCallback: self.processCellCallback(self)
        });
        
        this.exportData = false;
        this.inProgressShow = false;
        this.gridOptions.api.removeEventListener('modelUpdated', listener);
      };
      
      this.gridApi.refreshServerSide({purge: true});
      this.gridOptions.api.addEventListener('modelUpdated', listener);        
    },
    async docImportOk({ items }) {
      this.docImportShow = false;
      this.inProgressShow = true;
      this.resetAlert();
      await this.addCustomers(items);
      this.inProgressShow = false;
      this.gridApi.refreshServerSide({ purge: true });
    },
    parseSocial(social) {
      if (social.includes(':')) {
        const parts = social.split(': ');
        return { kind: parts[0], data: parts[1] };
      }
      return { kind: 'other', data: social }
    },
    async addCustomers(items) {
      this.inProgressLabel = this.$t('customer.progress.importing', [0]);
      let percentage = 0;
      const self = this;
      for (const item of items) {
        const data = {
          name: item.name,
          nickName: item.nickname,
          description: item.desc,
          identifier: item.identifier,
          color: item.color
        }
        
        if (item.socials &&
            item.socials.length > 0) {
          data['socials'] = item.socials.split(', ').map(p => { return self.parseSocial(p) });    
        }
        
        if (item.websites &&
            item.websites.length > 0) {
          data['websites'] = item.websites.split(', ');    
        }
        
        const result = await customerService.create([data])
        .then(response => {
          const feedbackList = response.data.feedbackList;
          if (Array.isArray(feedbackList) && 
                feedbackList.length > 0 && 
                feedbackList[0].uuId != null) {
            return feedbackList[0].uuId;
          }
        })
        .catch((e) => {
          this.httpAjaxError(e);
          return null;
        });
        
        if (result) {
          if (item.location) {
            await customerLinkLocationService.create(result, [item.location.uuId]);
          }
            
          if (item.tag) {
            await addTags(result, item.tag.split(',').map(t => { return { name: t.trim() }}), customerLinkTagService);
          }
          
          if (item.contact) {
            const contacts = item.contact.split('|');
            for (const contact of contacts) {
              const lines = contact.split(', ');
              const data = { lastName: '' };
              for (const line of lines) {
                const pair = line.split(': ');
                if (pair[0] === self.$t('contact.field.firstName')) {
                  data['firstName'] = pair[1];
                }
                else if (pair[0] === self.$t('contact.field.lastName')) {
                  data['lastName'] = pair[1];
                }
                else if (pair[0] === self.$t('contact.field.position')) {
                  data['position'] = pair[1];
                }
                else if (pair[0] === self.$t('contact.field.company')) {
                  data['company'] = pair[1];
                }
                else if (pair[0] === self.$t('contact.field.phones')) {
                  data['phones'] = [];
                  const arr = pair[1].split('; ');
                  for (const phone of arr) {
                    const pot = phone.split(' ');
                    data['phones'].push({ kind: pot[0], data: pot[1] });
                  }
                }
                else if (pair[0] === self.$t('contact.field.socials')) {
                  data['socials'] = [];
                  const arr = pair[1].split('; ');
                  for (const social of arr) {
                    const pot = social.split(' ');
                    if (pot.length > 0 && pot[0] !== '') {
                      data['socials'].push({ kind: pot[0], data: pot[1] });
                    }
                  }
                }
                else if (pair[0] === self.$t('contact.field.emails')) {
                  const lot = pair[1].split('; ');
                  if (lot.length > 0 && lot[0] !== '') {
                    data['emails'] = lot;
                  }
                }
                else if (pair[0] === self.$t('contact.field.websites')) {
                  const lot = pair[1].split('; ');
                  if (lot.length > 0 && lot[0] !== '') {
                    data['websites'] = lot;
                  }
                }
              }
              await contactService.create([data], result)
              .then(response => {
                const feedbackList = response.data.feedbackList;
                if (Array.isArray(feedbackList) && 
                      feedbackList.length > 0 && 
                      feedbackList[0].uuId != null) {
                  return feedbackList[0].uuId;
                }
              })
              .catch((e) => {
                this.httpAjaxError(e);
                return null;
              });
            }
          }
        }
        percentage++;
        this.inProgressLabel = this.$t('customer.progress.importing', [parseFloat(percentage / items.length * 100).toFixed(0)]);
      
      }
    },
    docImportCancel() {
      this.docImportShow = false;
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onColorChange(val) {
      for (const key of Object.keys(this.coloring)) {
        this.coloring[key] = false;
      }
      this.coloring[val] = true;
      this.settings['customer_selector_coloring'] = this.coloring;
      this.updateViewProfile();
      this.gridApi.redrawRows();
    },
    getRowColor(data) {
      if (data &&
        data.color &&
        this.coloring.company) {
        return data.color;
      }
      else if (data &&
        Array.isArray(data.locationColor) &&
        data.locationColor.length > 0 &&
        data.locationColor[0] !== '' &&
        this.coloring.location) {
        return data.locationColor[0];
      }
    },
    prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage
      }
      return this.$t(`entity_selector.customer_grid_no_data`)
    },
    showNoRowLabel(msg=null) {
      this.noRowsMessage = msg
      let api = this.gridOptions != null? this.gridOptions.api : null
      if (api != null) {
        this.gridOptions.api.hideOverlay()
        setTimeout(() => {
          api.showNoRowsOverlay()
        })
      }
    }
  }
}


</script>